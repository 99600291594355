import React from 'react';
import IGSVG from './assets/instagram.svg';
import IMDBSVG from './assets/imdb.svg';
import YTSVG from './assets/youtube.svg';

import "../components/style/global.css"

function Links() {
  return (
    <div className="container"
    style={{width: '100%',}}>
      <div className="row justify-content-center">
        <div className="col-1 ">
          <a href="https://www.youtube.com/channel/UC2trQ5eI9bpVAmDCPw7Pb9A">
          <img src={YTSVG} alt="YouTube Symbol"
                style={{ 
                  width: '3.6vw',
                  height: '3.6vh', 
                }} 
                />
          </a>
        </div>
        <div className="col-1 ">
          <a href="https://www.imdb.com/name/nm13929885/">
          <img src={IMDBSVG} alt="IMDB Symbol"
                style={{ 
                  width: '3.5vw',
                  height: '3.5vh',
                }} 
                />
          </a>
        </div>
        <div className="col-1  ">
          <a href="https://www.instagram.com/2ndteamproductionsofficial/">
          <img src={IGSVG} alt="Instagram Symbol"
                style={{ 
                  width: '3.5vw',
                  height: '3.5vh',
                 
                }} 
                />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Links;
