import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import '../components/style/global.css';
import Home from './pages/Home';
import Header from './Header';
import Footer from './Footer';
import About from './pages/About';
import MovieProfile from './pages/MovieProfile';
import Movies from './pages/Movies';
import HitchcockCollection from './pages/Hitchcock';
import Submit from './pages/Submit';

function WebsiteContainer() {
  const [currentPage, setCurrentPage] = useState('Home');

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <Header currentPage={currentPage} handlePageChange={handlePageChange} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/:moviename" element={<MovieProfile />} />
        <Route path="/Movies/:moviename" element={<Movies />} />
        <Route path="/HitchcockCollection" element={<HitchcockCollection />} />
        <Route path="/Submit" element={<Submit />} />
      </Routes>
      <Footer currentPage={currentPage} handlePageChange={handlePageChange} />
    </div>
  );
}

export default WebsiteContainer;
