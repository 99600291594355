import React from "react";
import { Nav, Navbar} from "react-bootstrap";
import SecondTeamLogo from './assets/2ndTeamF.jpg';

function NavTabs({ currentPage, handlePageChange }) {
  return (
    <Navbar collapseOnSelect expand="xxl"variant="dark">
  <Navbar.Brand href="/"
          onClick={() => handlePageChange('Home')}
          className= {currentPage === 'Home' ? 'nav-link active' : 'nav-link'} style={{flex: '0 0 auto',}}> 
           <img src={SecondTeamLogo} alt="Second Team Logo"
                style={{ 
                  maxWidth: '40vmin',
                  maxHeight: '40vmin',
                  marginRight: '12px',
                }} 
                />
     </Navbar.Brand>
     <Navbar.Toggle aria-controls="responsive-navbar-nav" /> 
     <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="mr-auto">
        <Nav.Link  href="/"
          onClick={() => handlePageChange('Home')}
          className={currentPage === 'Home' ? 'nav-link active' : 'nav-link'} style={{fontSize: "2vmin",  flex: '0 0 auto',}}>
            Home 
        </Nav.Link>
        {/* <Nav.Link href="/Services"
          onClick={() => handlePageChange('Services')}
          className={currentPage === 'Services' ? 'nav-link active' : 'nav-link'} style={{fontSize: "2vmin",  flex: '0 0 auto'}}>
           Services
        </Nav.Link> */}
        <Nav.Link  href="/about"
          onClick={() => handlePageChange('About')}
          className={currentPage === 'About' ? 'nav-link active' : 'nav-link'} style={{fontSize: "2vmin",  flex: '0 0 auto'}}>
          About
        </Nav.Link>
        <Nav.Link  href="/submit"
          onClick={() => handlePageChange('Submit')}
          className={currentPage === 'Submit' ? 'nav-link active' : 'nav-link'} style={{fontSize: "2vmin",  flex: '0 0 auto'}}>
          Submit
        </Nav.Link>
    </Nav>
    </Navbar.Collapse>
    </Navbar>
  );
}

export default NavTabs;