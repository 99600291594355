import React from 'react';
import { Card,} from 'react-bootstrap';
import IMDBSVG from '../assets/imdb.svg';
import LISVG from '../assets/linkedin-original.svg';
import nick from '../assets/Nicholas_James_Ortiz3.jpeg';
import secondTeam from '../assets/2ndTeam.jpg';
import txoutline from '../assets/txoutline.svg';

export default function About() {
  return (
    <div style={{ marginBottom: '7%',}}>
      <div className='d-flex justify-content-center' 
         style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'black',
          marginBottom: '1%',
          boxShadow: '0px 0px 10px white',
        }}
       >
        <img src={secondTeam} alt='secondteamlogo' className="img-fluid"/>
      </div>
      <div className='container'> 
     
      <div className='row align-items-center about-page-content'>
        <div className='d-flex justify-content-center about-us-header'>
        <h2 className='section-title' >About Us</h2>
        </div>
        <div className='container'>
        <Card style={{ marginBottom: '5%',}}>
          <h3 className="d-flex justify-content-center">Premium Content Created by Local Artists</h3>
            <Card.Text >
            At 2nd Team Productions, we support Texas filmmakers by providing a 
            content space dedicated to showcasing their artistry and ability to bring
            their creative vision to the silver screen. 
            </Card.Text>
            <Card.Text >
            We're currently in the process of generating original work so, in the meantime,
            please feel free to browse our collection of cinema classics.
            </Card.Text>
          </Card>
        </div>
        <br></br>
        <div style={{ position: 'relative', backgroundImage: `url(${txoutline})`, backgroundSize: '80% auto', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', }}>
          <Card className='about-card'> 
        <div className='row align-items-center'>
          <div className='col-8'> 
          <div> 
          <Card style={{padding:'5%',}}>
              <h2 className='section-title' >
                Nicholas James Ortiz</h2>
              <h3>
                Founder</h3>
              <Card.Text>
              Nicholas is an Austin-based Actor whose most recent role is in 
              Robert Rodriguez’s SpyKids: Armageddon. Since wrapping SpyKids, he was
              admitted into the Austin Film Society's Producer Program on scholarship.
              Aware that many shows filmed in Texas are comprised of cast and crew not 
              local to the state, Nicholas has endeavored to improve the visibility of the local film community.
              </Card.Text>
              <Card.Text>
              2nd Team Productions is the paragon of this dedication. Texas is home to an 
              unrivaled class of high caliber creative as well as business professionals 
              and its 2nd Team Productions mission to communicate this reality to the 
              entertainment industry at large. Contact us now to see how we can help 
              cultivate a robust Texas film industry, built by Texans.
              </Card.Text>
              <Card.Text>
                Nicholas has a BA from The University of Texas at Austin, an MS Marketing 
                degree with a specialization in entrepreneurship and consulting from the 
                University of Houston, as well as a full-stack web development 
                certificate from The University of Texas at Austin.
              </Card.Text>
            </Card>
            </div>
            </div>
            <div className='col-4' style={{position:'relative',}}>
              <img  
                src={nick} 
                alt='Nick' 
                className="img-fluid" 
                style={{
                  width: '75%',
                  height: '75%',
                  
                  maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                  WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                  transition: 'mask-image 0.3s ease',
                }}
                />
                 <div className="col-4 col-sm-1 d-flex justify-content-center">
         
          <a href="https://www.imdb.com/name/nm13929885/">
          <img src={IMDBSVG} alt="IMDB Symbol"
                style={{ 
                  width: '3.5vw',
                  height: '3.5vh',
                  marginRight: '12px',
                }} 
                />
          </a>
          <a href="https://www.linkedin.com/in/nicholasjortiz/">
          <img src={LISVG} alt="LinkedIn Symbol"
                style={{ 
                  width: '3.5vw',
                  height: '3.5vh',
                 
                }} 
                />
          </a>
         
        </div>
            </div>
            </div>
            </Card>
            </div>
        </div>
        
      </div>
    </div>
  );
}
