import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import WebsiteContainer from './components/WebsiteContainer';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <BrowserRouter>
      <WebsiteContainer />
    </BrowserRouter>
  );
}

export default App;
