import React from "react";
import { Nav, Navbar} from "react-bootstrap";
import SecondTeamLogo from './assets/2ndTeamF.jpg';

function FTabs({ currentPage, handlePageChange }) {
  return (
    <Navbar className="d-flex justify-content-center">
      <Nav className="mr-auto align-items-center">
        <Navbar.Brand href="/"
          onClick={() => handlePageChange('Home')}
          className= {currentPage === 'Home' ? 'nav-link active' : 'nav-link'}> 
           <img src={SecondTeamLogo} alt="Second Team Logo"
                style={{ 
                  maxWidth: '35vmin',
                  maxHeight: '35vmin',
                  marginRight: '12px',
                }} 
                />
        </Navbar.Brand>
    </Nav>
    </Navbar>
  );
}

export default FTabs;