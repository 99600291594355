import React, { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Muted from './assets/muted.svg';
import Unmuted from './assets/unmuted.svg';
import Replay from './assets/Replay.svg';

function MainTPlayer() {
  const [error, setError] = useState(null);
  const videoRef = useRef(null);
  const [muted, setMuted] = useState(true);
  const { moviename: movieParam } = useParams();
  const [movieData, setMovieData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (videoRef.current) {
      setMuted(videoRef.current.muted);
    }
  }, []);

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setMuted(videoRef.current.muted);
    }
  };

  const handleReplay = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }
  };

  useEffect(() => {
    const fetchMovieData = async () => {
      try {
        const response = await fetch(`https://second-team-productions.onrender.com/newmovies`);
        if (!response.ok) {
          throw new Error('Failed to fetch movie data');
        }
        const data = await response.json();
        const filteredMovies = data.filter(movie => movie.moviename === 'SEVEN SAMURAI');
        

        if (filteredMovies.length > 0) {
          setMovieData(filteredMovies[0]);
          setIsLoaded(true);
        } else {
          throw new Error('Movie data not found');
        }
      } catch (error) {
        console.error('Error fetching movie data:', error);
        setError(error.message);
      }
    };

    fetchMovieData();
  }, [movieParam]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <video
        playsInline
        autoPlay
        muted
        style={{
          width: '100%',
          height: '100%',
          marginBottom: '1%',
          maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
          WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
          transition: 'mask-image 0.3s ease',
        }}
        ref={videoRef}
      >
        <source src={movieData.trailer} type='video/mp4'></source>
      </video>
      {muted ? (
        <img
          src={Muted}
          alt="Muted Icon"
          onClick={toggleMute}
          style={{
            width: '3.5vw',
            height: '3.5vw',
            cursor: 'pointer',
          }}
        />
      ) : (
        <img
          src={Unmuted}
          alt="Unmuted Icon"
          onClick={toggleMute}
          style={{
            width: '3.5vw',
            height: '3.5vw',
            cursor: 'pointer',
          }}
        />
      )}
      <img
        src={Replay}
        alt="Replay Icon"
        onClick={handleReplay}
        style={{
          width: '3.5vw',
          height: '3.5vw',
          cursor: 'pointer',
          fill: 'white',
          marginLeft: '5px',
        }}
      />
    </div>
  );
}

export default MainTPlayer;
