import React from 'react';
import MovieProfile from './MovieProfile';

function Movies() {
 
  return (
    <div>
      <div>
        <div>
            <div>
                <MovieProfile ></MovieProfile>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Movies;
